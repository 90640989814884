exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basket-tsx": () => import("./../../../src/pages/basket.tsx" /* webpackChunkName: "component---src-pages-basket-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-market-tsx": () => import("./../../../src/pages/market.tsx" /* webpackChunkName: "component---src-pages-market-tsx" */),
  "component---src-pages-myaccount-tsx": () => import("./../../../src/pages/myaccount.tsx" /* webpackChunkName: "component---src-pages-myaccount-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-returns-js": () => import("./../../../src/pages/returns.js" /* webpackChunkName: "component---src-pages-returns-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-1-tsx": () => import("./../../../src/pages/signup1.tsx" /* webpackChunkName: "component---src-pages-signup-1-tsx" */),
  "component---src-pages-signup-2-tsx": () => import("./../../../src/pages/signup2.tsx" /* webpackChunkName: "component---src-pages-signup-2-tsx" */),
  "component---src-pages-signup-3-tsx": () => import("./../../../src/pages/signup3.tsx" /* webpackChunkName: "component---src-pages-signup-3-tsx" */),
  "component---src-pages-signup-4-tsx": () => import("./../../../src/pages/signup4.tsx" /* webpackChunkName: "component---src-pages-signup-4-tsx" */),
  "component---src-pages-signup-5-tsx": () => import("./../../../src/pages/signup5.tsx" /* webpackChunkName: "component---src-pages-signup-5-tsx" */),
  "component---src-pages-signup-6-tsx": () => import("./../../../src/pages/signup6.tsx" /* webpackChunkName: "component---src-pages-signup-6-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-verifyemail-tsx": () => import("./../../../src/pages/verifyemail.tsx" /* webpackChunkName: "component---src-pages-verifyemail-tsx" */),
  "component---src-pages-waitlist-tsx": () => import("./../../../src/pages/waitlist.tsx" /* webpackChunkName: "component---src-pages-waitlist-tsx" */)
}

